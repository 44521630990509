import React from 'react'

// tailwindCss
import './src/components/layout.css';

//firebase auth for full cv
import 'firebase/auth'
import AuthProvider from './src/context/auth'

export const wrapRootElement = ({ element }) => (
    <AuthProvider>{element}</AuthProvider>
)